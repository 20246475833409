<template>
  <div class="">
    <div class="actions__table table__titles">
      <div class="table__title">#</div>
      <div class="table__title">Агент</div>
      <div class="table__title">Тип</div>
      <div class="table__title">Дата</div>
      <div class="table__title">Час</div>
      <div class="table__title">Об’єкт</div>
      <div class="table__title">Покупець/Ор</div>
      <div class="table__title">Результат</div>
    </div>
    <div class="actions__wrapper screen__wrapper">
      <router-view />
      <Action v-for="(action, i) in actions" :key="action.id" :action="action" :num="i" @click.native="info(action)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadAct"></infinite-loading>
  </div>
</template>

<script>
import Action from './Action'
import InfiniteLoading from 'vue-infinite-loading'
import ModalInfoAction from '@/views/Actions/InfoAction'

export default {
  name: 'Actions',
  components: {
    Action,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    actions() {
      return this.$store.getters.actions
    },
    hasMoreResults() {
      return this.actions.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters.pagActions.total
    },
    page() {
      return this.$store.getters.pageActions
    }
  },
  methods: {
    info(action) {
      const style = this.$modal.stylesSmall

      this.$modal.display(ModalInfoAction, {action}, {...style, name: 'modalInfoAction', classes: 'modal modal-action',})

      // this.$modal.show(
      //   ModalInfoAction,
      //   {action: action},
      //   {...style, name: 'modalInfoAction'},
      //   {'before-open': this.$modal.open, 'before-close': this.$modal.close}
      // )
    },
    async loadAct() {
      this.isLoading = true
      try {
        await this.$store.dispatch('fetchActions')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
