var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "actions__wrapper screen__wrapper" },
        [
          _c("router-view"),
          _vm._l(_vm.actions, function(action, i) {
            return _c("Action", {
              key: action.id,
              attrs: { action: action, num: i },
              nativeOn: {
                click: function($event) {
                  return _vm.info(action)
                }
              }
            })
          })
        ],
        2
      ),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.loadAct } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "actions__table table__titles" }, [
      _c("div", { staticClass: "table__title" }, [_vm._v("#")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Агент")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Тип")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Дата")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Час")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Об’єкт")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Покупець/Ор")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Результат")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }